import {QueryClientProvider, QueryClient} from 'react-query'
import React from 'react'

const AppProvider = ({children}: { children: React.ReactNode }) => {
	const client = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				refetchOnReconnect: false
			},
		},
	})
	return <QueryClientProvider client={client}>{children}</QueryClientProvider>
}

export default AppProvider
