import { getThemeId, getToken } from '../utils/auth'

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const token = getToken()
	const themeId = getThemeId()
	if (!token || !themeId) {
		return (
			<div>
				token: {token}----themeId: {themeId}
			</div>
		)
	}
	return <div>{children}</div>
}
