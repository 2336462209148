import {useCallback} from 'react'
import {useEffect} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import backIcon from '@/assets/images/backIcon.png'
import styles from './index.module.less'
import {useSubmitGame} from './hooks'

const Game = () => {
	const navigate = useNavigate()
	const {stage_id = '', theme_id} = useParams<{
		theme_id: string
		stage_id: string
	}>()
	const [params] = useSearchParams()
	const {mutateAsync} = useSubmitGame()
	const handleIframeCallback = useCallback(async (e: any) => {
		console.log(e)
		if (e.data && e.data.type === 'GAME_FINISH') {
			await mutateAsync({
				stage_id: Number(stage_id),
				them_id: Number(theme_id),
				game_score: e.data.score,
			})
		} else if (e.data && e.data.type === 'GAME_BACK') {
			navigate(('/'))
		}
	}, [mutateAsync, navigate, stage_id, theme_id])
	useEffect(() => {
		window.addEventListener('message', handleIframeCallback)
		return () => {
			window.removeEventListener('message', handleIframeCallback)
		}
	}, [handleIframeCallback])
	return (
		<div className={styles.game}>
			<div className={styles.header}>
				<img
					alt='back'
					src={backIcon}
					className={styles.backIcon}
					onClick={() => {
						navigate('/')
					}}
				/>
			</div>
			<iframe id='iframe' title="game" src={params.get('game_url') as string}/>
		</div>
	)
}

export default Game
