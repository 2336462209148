import styles from './index.module.less'

import backIcon from '@/assets/images/backIcon.png'
import rankTitle from '@/assets/images/rankTitle.png'
import classNames from 'classnames'
import {useNavigate} from 'react-router-dom'
import {useRankList} from './hooks'
import {getThemeId} from '@/utils/auth'

const defaultAvatar = 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/avatar19.png'

const Rank = () => {
	const navigate = useNavigate()
	const {data} = useRankList(Number(getThemeId()))
	return (
		<div className={styles.rank}>
			<div className={styles.header}>
				<img
					alt='back'
					src={backIcon}
					className={styles.backIcon}
					onClick={() => {
						navigate('/')
					}}
				/>
				<img alt='title' src={rankTitle} className={styles.rankTitle}/>
			</div>
			<div className={styles.content}>
				<div className={styles.topRank}>
					<div className={styles.rank2}>
						<div className={styles.avatar}>
							{data?.[1] && <img alt='avatar' src={data?.[1].avatar_url || defaultAvatar}/>}
						</div>
						<div className={styles.username}>{data?.[1]?.nick_name}</div>
						<div className={classNames(styles.rankNum, {[styles.two]: true})}/>
					</div>
					<div className={styles.rank1}>
						<div className={styles.avatar}>
							{data?.[0] && <img alt='avatar' src={data?.[0].avatar_url || defaultAvatar}/>}
							<div className={styles.crown}/>
						</div>
						<div className={styles.username}>{data?.[0]?.nick_name}</div>
						<div className={classNames(styles.rankNum, {[styles.one]: true})}/>
					</div>
					<div className={styles.rank3}>
						<div className={styles.avatar}>
							{data?.[2] && <img alt='avatar' src={data?.[2].avatar_url || defaultAvatar}/>}
						</div>
						<div className={styles.username}>{data?.[2]?.nick_name}</div>
						<div className={classNames(styles.rankNum, {[styles.three]: true})}/>
					</div>
				</div>
				<div className={styles.rankList}>
					<div className={styles.rankContent}>
						{data?.map((item, index) => (
							<div
								key={item.persons_id}
								className={classNames(styles.rankItem, {
									[styles.my]: item.is_myself === 1,
									[styles.fixed]: item.is_myself === 1 && index > 10,
								})}
							>
								<span
									className={classNames(styles.index, {
										[styles.one]: index === 0,
										[styles.two]: index === 1,
										[styles.three]: index === 2,
									})}
								>
									{index + 1}
								</span>
								<span className={styles.avatar}>
									<img alt='avatar' src={item.avatar_url || defaultAvatar}/>
								</span>
								<span className={styles.username}>{item.nick_name}</span>
								<span className={styles.score}>{item.persons_score}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Rank
