import { getRankList } from '@/services/api'
import { useQuery } from 'react-query'
export const useRankList = (them_id: number) => {
	const { data, ...rest } = useQuery(['rank', them_id], () => getRankList({ them_id }), {
		enabled: !!them_id,
	})
	return {
		data: data?.data,
		...rest,
	}
}
