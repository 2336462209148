export const AuthTokenKey = '__gh_activity_auth_token__'

export const AuthThemeIdKey = '__gh_activity_auth_theme_id__'

export const getToken = () => window.localStorage.getItem(AuthTokenKey)

export const setToken = (token: string) => window.localStorage.setItem(AuthTokenKey, token)

export const getThemeId = () => window.localStorage.getItem(AuthThemeIdKey)

export const setThemeId = (themeId: string) => window.localStorage.setItem(AuthThemeIdKey, themeId)
