import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from '@/context/auth'
import Auth from '@/pages/Auth'
import Home from '@/pages/Home'
import Rank from '@/pages/Rank'
import Question from '@/pages/Question'
import Game from './pages/Game'

const AuthenticatedApp = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/auth' element={<Auth />} />
				<Route
					path='/'
					element={
						<AuthProvider>
							<Home />
						</AuthProvider>
					}
				/>
				<Route
					path='/rank'
					element={
						<AuthProvider>
							<Rank />
						</AuthProvider>
					}
				/>
				<Route
					path='/question/:theme_id/:stage_id'
					element={
						<AuthProvider>
							<Question />
						</AuthProvider>
					}
				/>
				<Route
					path='/game/:theme_id/:stage_id'
					element={
						<AuthProvider>
							<Game />
						</AuthProvider>
					}
				/>
			</Routes>
		</BrowserRouter>
	)
}

export default AuthenticatedApp
