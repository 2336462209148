export class SysError extends Error {
  public code;

  constructor(message: string, code: number) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
  }
}

export class PremiseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
