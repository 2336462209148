import {getQuestionList, getStageScore, submitQuestion, SubmitQuestionParams} from '@/services/api'
import {useMutation, useQuery} from 'react-query'

export const useQuestionList = (stage_id: number) => {
	const {data, ...rest} = useQuery(['question', stage_id], () => getQuestionList({stage_id}), {
		enabled: !!stage_id,
	})
	return {
		data: data?.data || {list: [], flag: 1},
		...rest,
	}
}

export const useQuestionScore = (stage_id: number, theme_id: number) => {
	const {data, ...rest} = useQuery(['QuestionScore', stage_id, theme_id], () => getStageScore({
		stage_id,
		them_id: theme_id
	}), {
		enabled: !!stage_id,
	})
	return {
		data: data?.data || [],
		...rest,
	}
}

export const useSubmitQuestion = () => {
	return useMutation((data: SubmitQuestionParams) => submitQuestion(data))
}
