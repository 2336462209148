import styles from './index.module.less'
import React from 'react';
import classNames from 'classnames';

const Modal = ({children, open, onClose}: { children: React.ReactElement, open: boolean, onClose: () => void }) => {
	return (
		<div className={classNames(styles.modal, {[styles.show]: open})}>
			<div className={styles.overlay}></div>
			<div className={styles.content}>
				<div className={styles.close} onClick={onClose}/>
				{children}
			</div>
		</div>
	)
}

export default Modal
