import { getThemeActivity } from '@/services/api'
import { useQuery } from 'react-query'

export const useActivity = (them_id: number) => {
	const { data, ...rest } = useQuery(['activity', them_id], () => getThemeActivity({ them_id }), {
		enabled: !!them_id,
	})
	return {
		data: data?.data,
		...rest,
	}
}
