import styles from '@/pages/Question/index.module.less';
import {useNavigate} from 'react-router-dom';
import {useQuestionScore} from '@/pages/Question/hooks';
import classNames from 'classnames';

const QuestionFinish = ({
	                        stage_id,
	                        theme_id,
	                        second,
	                        oneMoreTime,
	                        handlePause
                        }: { stage_id: number, theme_id: number, second: number, oneMoreTime: () => void, handlePause: () => void }) => {
	const navigate = useNavigate()

	const {data} = useQuestionScore(stage_id, theme_id)

	return (
		<div className={styles.completed}>
			{
				data.persons_score >= data.stage_score ? <div className={styles.pass}>恭喜闯关成功</div> :
					<div className={styles.error}>很遗憾闯关失败</div>
			}
			{
				data.persons_score >= data.stage_score && (
					<div className={styles.gif}>
						<div className={styles.left}/>
						<div className={styles.right}/>
					</div>
				)
			}
			<div
				className={classNames(styles.score, {[styles.pass]: data.persons_score >= data.stage_score})}>{data?.persons_score || 0}<span>分</span>
			</div>
			<div className={styles.second}><span className={styles.text}>用时：</span>{second}<span>秒</span></div>
			<div className={styles.wrap}>
				<div className={styles.backBtn} onClick={() => {
					handlePause()
					navigate('/')
				}}></div>
				<div className={styles.successBtn} onClick={oneMoreTime}></div>
			</div>

		</div>
	)
}

export default QuestionFinish
