import {getThemeId} from '@/utils/auth'
import classNames from 'classnames'
import {useNavigate} from 'react-router-dom'
import {useActivity} from './hooks'
import styles from './index.module.less'
import backIcon from '@/assets/images/backIcon.png'
import {useRef, useState} from 'react'
import Modal from '@/components/Modal'

const Home = () => {
	const navigate = useNavigate()
	const audioRef = useRef<any>(null)
	const [isPlay, setIsPlay] = useState(false)
	const [open, setOpen] = useState(false)
	const {data} = useActivity(Number(getThemeId()))
	const handleClickStage = (index: number) => {
		const target = data?.stage_list[index]
		if (target) {
			const prevIndex = index + 1
			if (
				data.stage_list[prevIndex]?.persons_score < data.stage_list[prevIndex]?.stage_score
			) {
				return
			}
			if (target.stage_type === 2) {
				navigate(`/question/${data.them_id}/${target.stage_id}`)
				return
			}
			if (target.stage_type === 3) {
				navigate(`/game/${data.them_id}/${target.stage_id}?game_url=${target.game_info?.game_url}`)
				return
			}
		}
	}

	const handlePlay = () => {
		if (audioRef.current) {
			audioRef.current.play()
			setIsPlay(true)
		}
	}
	const handleStop = () => {
		if (audioRef.current) {
			audioRef.current.pause()
			setIsPlay(false)
		}
	}
	return (
		<div className={styles.home} style={{backgroundImage: `url(${data?.them_bg})`}}>
			<div className={styles.header}>
				<img
					alt='back'
					src={backIcon}
					className={styles.backIcon}
					onClick={() => {
						//@ts-ignore
						wx.miniProgram.switchTab({url: '/pages/tabBar/home/home'})
					}}
				/>
				<div className={styles.title}></div>
				<div className={styles.rankBtn} onClick={() => navigate('/rank')}/>
				{!isPlay ? (
					<div className={styles.stop} onClick={handlePlay}></div>
				) : (
					<div className={styles.play} onClick={handleStop}></div>
				)}
				<div className={styles.tip} onClick={() => setOpen(true)}>
					奖品
				</div>
			</div>
			<div className={styles.content}>
				{data?.stage_list.map((item, index) => (
					<div
						key={item.stage_id}
						className={classNames(styles[`stage${index}`], {
							[styles.stage]: true,
							[styles.completed]: item.persons_score >= item.stage_score,
							[styles.game]: item.stage_type === 3,
							[styles.question]: item.stage_type !== 3,
						})}
						onClick={() => handleClickStage(index)}
					>
						<div
							className={classNames(styles[`title${index}`], {[styles.stageTitle]: true})}
						></div>
					</div>
				))}
			</div>
			{/*<div className={styles.footer}></div>*/}
			<audio ref={audioRef} autoPlay={false} loop preload='auto' controls>
				<source
					src='https://guohua-institution.oss-cn-beijing.aliyuncs.com/bgm1.mp3'
					type='audio/mpeg'
				/>
			</audio>
			<Modal open={open} onClose={() => setOpen(false)}>
				<div className={styles.modalContent}>
					<div
						className={styles.item}
						dangerouslySetInnerHTML={{__html: data?.them_abbr || ''}}
					></div>
				</div>
			</Modal>
		</div>
	)
}

export default Home
