import './App.css'
import ErrorBoundary from './components/ErrorBoundary'
import AuthenticatedApp from './AuthenticatedApp'

const App = () => {
	return (
		<div className='App'>
			<ErrorBoundary fallbackRender={() => <div>error</div>}>
				<AuthenticatedApp />
			</ErrorBoundary>
		</div>
	)
}

export default App
