import styles from './index.module.less'

import backIcon from '@/assets/images/backIcon.png'
import questionTitle from '@/assets/images/questionTitle.png'
import {useQuestionList, useSubmitQuestion} from './hooks'
import {useNavigate, useParams} from 'react-router-dom'
import {useRef, useState} from 'react'
import QusetionOptions, {Value} from './components/QuestionOptions'
import classNames from 'classnames'
import QuestionFinish from '@/pages/Question/components/QuestionFinish';
import {getStageScore} from '@/services/api';

const BASE_SCORE = 20
const BASE_SECOND = 30

const Question = () => {
	const navigate = useNavigate()
	const audioRef = useRef<any>(null)
	const audioFailRef = useRef<any>(null)
	const currentDate = useRef(new Date().getTime())
	const finishedDate = useRef(new Date().getTime())
	const {stage_id = '', theme_id} = useParams<{ theme_id: string; stage_id: string }>()
	const [current, setCurrent] = useState(0)
	const [checked, setChecked] = useState<Value>({id: undefined, value: []})
	const {data: {list: data, flag}, refetch} = useQuestionList(Number(stage_id))
	const {mutateAsync: submitQuestion, isLoading} = useSubmitQuestion()

	const remaining = data.length - current - 1
	const currentQuestion = data[current]
	const disabled = checked.value.length === 0

	const handleNextQuestion = async () => {
		let timeScore = 0
		if (current > data.length || disabled || isLoading) {
			return
		}
		// 题目答完了
		if (current === data.length - 1) {
			const score = new Date().getTime()
			finishedDate.current = score
			const diffSecond = Math.ceil((score - currentDate.current) / 1000)
			timeScore = Math.ceil(BASE_SCORE + (BASE_SECOND - diffSecond) * 0.4)
		}
		await submitQuestion({
			them_id: Number(theme_id),
			stage_id: Number(stage_id),
			activity_question_id: checked.id as number,
			question_option_id: checked.value.join(','),
			time_score: timeScore > 0 ? timeScore : undefined,
			flag
		})
		if (current === data.length - 1) {
			const {data} = await getStageScore({
				them_id: Number(theme_id),
				stage_id: Number(stage_id),
			})
			if (data.persons_score >= data.stage_score) {
				handlePassPlay()
			} else {
				handleFailPlay()
			}
		}
		setChecked({id: undefined, value: []})
		setCurrent(current + 1)
	}

	const handlePassPlay = () => {
		if (audioRef.current) {
			audioRef.current.play()
		}
	}
	const handleFailPlay = () => {
		if (audioRef.current) {
			audioFailRef.current.play()
		}
	}
	const handlePause = () => {
		audioRef.current.pause()
		audioFailRef.current.pause()
	}

	return (
		<div className={styles.question}>
			<div className={styles.header}>
				<img
					alt='back'
					src={backIcon}
					className={styles.backIcon}
					onClick={() => {
						navigate('/')
					}}
				/>
				<img alt='title' src={questionTitle} className={styles.questionTitle}/>
			</div>
			{
				data.length > 0 && current > data.length - 1 ? (
					<QuestionFinish
						theme_id={Number(theme_id)}
						stage_id={Number(stage_id)}
						second={Math.ceil((finishedDate.current - currentDate.current) / 1000)}
						oneMoreTime={() => {
							refetch()
							setCurrent(0)
							setChecked({value: [], id: undefined})
							currentDate.current = new Date().getTime()
							finishedDate.current = new Date().getTime()
							handlePause()
						}}
						handlePause={handlePause}
					/>
				) : (
					<div className={styles.container}>
						<div className={styles.questionContent}>
							<div className={styles.titleTip}>
						<span>
							第<span className={styles.step}>{current + 1}</span>题
						</span>
								<span>
							剩余题目：<span className={styles.free}>{remaining}</span>
						</span>
							</div>
							<div
								className={styles.content}>（{currentQuestion?.subject_type === 'checkbox' ? '多选' : '单选'}）{currentQuestion?.subject_name}</div>
						</div>
						<QusetionOptions
							options={currentQuestion?.subject_list_info || []}
							type={currentQuestion?.subject_type}
							checkedValues={checked}
							onChange={setChecked}
						/>
						<div
							className={classNames(styles.nextBtn, {[styles.disabled]: disabled, finish: current === data.length - 1})}
							onClick={handleNextQuestion}
						></div>
					</div>
				)
			}
			<audio ref={audioRef} autoPlay={false} loop preload="auto" controls>
				<source src="https://guohua-institution.oss-cn-beijing.aliyuncs.com/bgm-success.mp3" type="audio/mpeg"/>
			</audio>
			<audio ref={audioFailRef} autoPlay={false} loop preload="auto" controls>
				<source src="https://guohua-institution.oss-cn-beijing.aliyuncs.com/bgm_fail.mp3" type="audio/mpeg"/>
			</audio>

		</div>
	)
}

export default Question
