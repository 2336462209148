import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { setThemeId, setToken } from '../../utils/auth'
const Auth = () => {
	const [search] = useSearchParams()
	const navigate = useNavigate()
	useEffect(() => {
		const token = search.get('t')
		const themeId = search.get('theme_id')
		if (token && themeId) {
			setToken(token)
			setThemeId(themeId)
			navigate('/')
		}
	}, [search, navigate])

	return <div>loading</div>
}

export default Auth
