import classNames from 'classnames'
import styles from '../index.module.less'

export type Value = { id?: number; value: number[] }

interface QusetionOptionsProps {
	options: API.QuestionOption[]
	type: API.QuestionType
	checkedValues: Value
	onChange?: (value: Value) => void
}

const QusetionOptions = (props: QusetionOptionsProps) => {
	const {options, type, checkedValues: checked, onChange} = props
	const handleCheck = (id: number, value: number) => {
		let {value: checkedValues} = checked
		if (type === 'checkbox') {
			if (checkedValues.includes(value)) {
				checkedValues = checked.value.filter((v) => v !== value)
			} else {
				checkedValues.push(value)
			}
		} else if (type === 'radio') {
			checkedValues = [value]
		}
		onChange?.({id, value: checkedValues})
	}
	return (
		<div className={styles.anwserList}>
			{options.map((opt) => (
				<span
					key={opt.rel_question_option_id}
					className={classNames(styles.answerItem, {
						[styles.current]: checked.value?.includes(opt.rel_question_option_id),
					})}
					onClick={() => handleCheck(opt.question_id, opt.rel_question_option_id)}
				>
					{opt.question_option_name}
				</span>
			))}
		</div>
	)
}

export default QusetionOptions
